import React from 'react';

function Home() {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 0; // Offset for scrolling 100px lower
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const position = elementRect - bodyRect - offset;

      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  };
  return (
    <section id="home" className='home-section'>
      <div className="text-container">
      <h1 >TRANSFORM YOUR BUSINESS WITH AI SOLUTIONS</h1>
      <p >Empower your business with cutting-edge AI tools designed to enhance efficiency, reduce costs, and drive growth.</p>
      <button onClick={() => scrollToSection('contact')}>Get Started Today</button>
      </div>
    <div className="home-logo" style={{backgroundImage: "url('./irobo.png')", width: "60%",
              backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "100%",
              borderRadius: "40px"
    }}>
      
      </div>
    </section>
    
  );
}


export default Home;