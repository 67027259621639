import React, {useState} from 'react';
import { ReactComponent as Logo_en } from '../envelope-solid.svg';
import { ReactComponent as Logo_ph } from '../phone-solid.svg';
import { ReactComponent as Logo_lo } from '../location-dot-solid.svg';
import axios from "axios";
function Contact() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  
  const [responseMessage, setResponseMessage] = useState(null);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setTimeout(()=>setResponseMessage(null), 3000)
      const response = await axios.post("https://rtpinfosolutions.com/contact-form.php", formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      setResponseMessage(response.data.message || "Your message has been sent!");
      setFormData({ name: "", email: "", message: "" }); // Reset form
    } catch (error) {
      setResponseMessage(
        error.response?.data?.message || "An error occurred. Please try again."
      );
    }
  };
  return (
    <section id="contact" className="contact-section">
    <div className="contact-container">
      <div className="contact-info">
        <label>Why RTP Info Solutions?</label>
        <p>Tailored Solutions: We understand that every SME is unique. Our AI tools are customized to meet your specific needs and challenges.</p>
        <p>Affordable Excellence: Cutting-edge AI doesn’t have to break the bank. We offer cost-effective solutions without compromising quality.</p>
        <p>Expertise You Can Rely On: Our team combines deep industry knowledge with advanced AI expertise to deliver impactful results.</p>
        <p>Ease of Integration: We ensure a seamless integration of AI into your existing workflows, minimizing disruption and maximizing efficiency.</p>
        <p>Commitment to Growth: Your success is our success. We’re here to support your business at every step, from implementation to scaling up.</p>
      </div>
  
      <div className="contact-form-container">
        <label>Contact Us</label>
        <div className="contact-svn" style={{ marginBottom: "10px"}}>
        <Logo_en style={{ width: '18px', height: '18px', marginRight: "10px"}} fill='#CFD6FF'/>
          <p>connect@rtpinfosolutions.com</p>
          </div>
          <div className="contact-svn"style={{ marginBottom: "10px"}}>
          <Logo_ph style={{ width: '18px', height: '18px', marginRight: "10px"}} fill='#CFD6FF'/>
          <p>+1 (984) 584-7688</p>
          </div>
          <div className="contact-svn" >
        <Logo_lo style={{ width: '18px', height: '18px', marginRight: "10px"}} fill='#CFD6FF'/>
          <p>800 Park Office Dr., Suite #3705, Durham, NC 27709.</p>
        </div>
        <form className="contact-form" onSubmit={handleSubmit}>
          <label>Name</label>
          <input type="text" placeholder='Name' name="name" value={formData.name}
            onChange={handleChange}
            required/>
          <label>Your email</label>
          <input type="email" placeholder='Your email' name="email" value={formData.email}
            onChange={handleChange}
            required/>
          <label>Message</label>
          <textarea placeholder='Message' name="message" value={formData.message}
            onChange={handleChange}
            required></textarea>
          <button type="submit">Send</button>
        </form>
        {responseMessage ? (<div className='message-info'>{responseMessage}</div>):null}
        
      </div>
    </div>
  </section>
  
  );
}

export default Contact;