import React from 'react';

function About() {
  return (
    <section id="about" className="ggc about-section" >
      <label>About Us</label>
  <p>
    Choosing the right partner for your AI journey can make all the difference. Here's why businesses trust RTP Info Solutions
  </p>
  <div className="about-cards-container">
    <div className="about-card">
      <label>Mission Statement</label>
      <p>
        Empowering small and medium enterprises to thrive in the digital era by delivering innovative, accessible, and scalable AI solutions that enhance efficiency, foster growth, and unlock new opportunities.
      </p>
    </div>
    <div className="about-card">
      <label>What We Do</label>
      <p>
        We specialize in delivering state-of-the-art AI solutions. Our goal is to simplify the adoption of artificial intelligence, enabling businesses to overcome challenges and seize opportunities in an increasingly competitive market.
      </p>
    </div>
  </div>
    </section>
  );
}

export default About;