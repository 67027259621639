import React from 'react';

function Footer() {
  return (
    <footer style={{ textAlign: 'center', padding: '20px', backgroundColor: '#000A4C', color: "rgb(135 142 180)", fontSize:"1rem", paddingTop: "20px" }}>
      
      <p>&copy; 2024 RTP Info Solutions. All Rights Reserved.</p>
    </footer>
  );
}

export default Footer;