import React from 'react';

function Services() {
  return (
    <section id="services" className="services-section">
  <label>Our Services</label>
  <p>
    Our AI-Powered Data Analytics services empower you to transform data into a competitive advantage, paving the way for sustainable growth and success.
  </p>
  <div className="services-container">
    <div className="services-image"></div>
    <div className="services-details">
      <ul className="services-list">
        <li>
          <strong>AI-Powered Data Analytics</strong>
          Real-time insights into sales, operations, and customer trends
        </li>
        <li>
          <strong>Process Automation</strong>
          Automate repetitive tasks like inventory management, scheduling, or invoicing.
        </li>
        <li>
          <strong>Custom AI Models</strong>
          Develop AI solutions tailored to specific business needs.
        </li>
        <li>
          <strong>Chatbots and Customer Support</strong>
          24/7 AI-driven customer support tools.
        </li>
      </ul>
    </div>
  </div>
</section>

  );
}

export default Services;