import React from 'react';

function CaseStudies() {
  return (
    <section >
     
    </section>
  );
}

export default CaseStudies;