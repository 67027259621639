import React, { useState, useEffect } from 'react';

function Header() {
  const [isScrolled, setIsScrolled] = useState(false);

  // Track scroll position
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = 0; // Offset for scrolling 100px lower
      const bodyRect = document.body.getBoundingClientRect().top;
      const elementRect = element.getBoundingClientRect().top;
      const position = elementRect - bodyRect - offset;

      window.scrollTo({ top: position, behavior: 'smooth' });
    }
  };
  return (
    <header style={{boxShadow: isScrolled ? '0 4px 6px rgba(0, 0, 0, 0.1)' : 'none'}}>
      <div className="header-content">
      <div style={{display:"flex", alignItems: "center"}}>
        <i className='logo'></i>
        <div style={{display: "flex", flexDirection: "column"}}>
      <label className='logo-title' >RTP INFO SOLUTIONS</label>
      <label className="logo-sub-title" >GEN AI SOLUTIONS @ WORK</label>
      </div>
      </div>
      <nav className='header-links'>
      
        <label onClick={() => scrollToSection('home')}>Home</label>
        <label onClick={() => scrollToSection('about')} >About Us</label>
        <label onClick={() => scrollToSection('services')}>Our Services</label>
        <label onClick={() => scrollToSection('contact')}>Contact Us</label>
      
      </nav>
      </div>
    </header>
  );
}

export default Header;